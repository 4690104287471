import Vue from 'vue'
import lazySizes from 'lazysizes'
import PhotoGrid from 'components/PhotoGrid'
import Gallery from 'components/Gallery'
import GalleryItem from 'components/GalleryItem'

import './gridzy.min.js'

Vue.component('photo-grid', PhotoGrid)
Vue.component('gallery', Gallery)
Vue.component('gallery-item', GalleryItem)

// eslint-disable-next-line
Gridzy.setDefaultOptions({
  useOptionAttributes: false,
})

new Vue({
  data() {
    return {
      isMobileActive: false,
    }
  },

  mounted() {
    lazySizes.init()
  },
}).$mount('#app')
