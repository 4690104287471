<template>
	<a class="gallery-item" :href="url" @click.prevent="onClick">
		<slot />
	</a>
</template>

<script>
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUI_Default from 'photoswipe/dist/photoswipe-ui-default'
import Event from 'Service/Event'

export default {
	inject: {
		gallery: {
			default: null,
		},
	},
	props: {
		url: {
			default: null,
		},
		width: {
			default: null,
		},
		height: {
			default: null,
    },
    title: {
			default: null,
		},
	},
	methods: {
		onClick() {
			this.openLightBox()
		},
		getPosition() {
			const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
			const rect = this.$el.getBoundingClientRect()

			return {
				x: rect.left,
				y: rect.top + pageYScroll,
				w: rect.width,
			}
		},
		openLightBox() {
			const container = document.getElementsByClassName('pswp')[0]

			const index = parseInt(Object
				.keys(this.gallery.items)
				.find(key => this.gallery.items[key].src === this.url), 10)

			this.lightBox = new PhotoSwipe(
				container,
				PhotoSwipeUI_Default,
				this.gallery.items,
				{
					index,
					bgOpacity: 0.9,
					shareEl: false,
					history: false,
					showHideOpacity: true,
					getThumbBoundsFn: this.getPosition,
				},
			)

			this.lightBox.init()
		},
	},
	mounted() {
		this.$nextTick(() => {
			Event.emit(`addGalleryItem:${this.gallery.id}`, {
				galleryId: this.gallery.id,
				src: this.url,
				w: parseInt(this.width, 10),
        h: parseInt(this.height, 10),
        title: this.title,
			})
		})
	},
}
</script>

<style lang="scss" src="./style.scss" scoped></style>
