<template>
  <div class="gridzy">
    <slot />
  </div>
</template>

<script>
export default {
  mounted() {
    this.grid = new Gridzy(this.$el, {
      animate: false,
      layout: 'justified',
      spaceBetween: 10,
      desiredHeight: 400,
    })
  },

  beforeDestroy() {
    this.grid.destroy()
    this.grid = null
  }
}
</script>
