<template>
	<div class="gallery">
		<slot />
	</div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import Event from 'Service/Event'

export default {
	data() {
		return {
			id: uuidv4(),
			lightBox: null,
			items: [],
		}
	},
	provide() {
		const gallery = {}

		Object.defineProperty(gallery, 'id', {
			enumerable: true,
			get: () => this.id,
		})

		Object.defineProperty(gallery, 'items', {
			enumerable: true,
			get: () => this.items,
		})

		return {
			gallery,
		}
	},
	mounted() {
		Event.on(`addGalleryItem:${this.id}`, item => {
			this.items.push(item)
		})
	},
}
</script>
